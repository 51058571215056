@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-icon{
  height: 40px;
  width: 40px;
  color: #22C55E;
}

.underline-title{
  height: 3px;;
  background-color: #22C55E;
}

.green-icon{
  color: #22C55E;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.phone-call:hover{
  color: white;
}